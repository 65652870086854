<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title">
                  <h4 class="mb-4">{{ $t('password.reset.page.title') }}</h4>
                  <p>{{ $t('password.reset.page.message') }}</p>
                </div>

                <ValidationObserver v-slot="{ invalid, handleSubmit }">
                  <form @submit.prevent="handleSubmit(updatePassword)">
                    <div class="vx-row">

                      <div class="vx-col w-full">
                        <ValidationProvider :name="$t('administration.userform.form.input.password.new')" rules="password:@confirmPassword" v-slot="{ errors }">
                          <vs-input class="w-full mt-4" :label="$t('administration.userform.form.input.password.new')" v-model="userLocal.plainPassword" name="password" type="password" />
                          <span class="error-class">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <ValidationProvider :name="$t('administration.userform.form.input.password.confirm')" vid="confirmPassword" v-slot="{ errors }">
                          <vs-input class="w-full mt-4" :label="$t('administration.userform.form.input.password.confirm')" v-model="confirmPassword" name="confirmPassword" type="password" />
                          <span class="error-class">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <div class="mt-8">
                          <vs-button button="submit" color="warning" class="w-full mb-2" @click="updatePassword" :disabled="invalid">{{ $t('password.reset.button.update_password') }}</vs-button>
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import moduleUserManagementMixin from '@/store/user-management/moduleUserManagementMixin'
export default {
  mixins: [moduleUserManagementMixin],
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  data () {
    return {
      userLocal: {
        plainPassword: ''
      },
      confirmPassword: ''
    }
  },
  methods: {
    async decodeJWT (token) {
      await this.$store.dispatch('auth/decodeJWT', token)
      await this.$store.commit('auth/SET_BEARER', token)
      this.userLocal = Object.assign({}, this.activeUserInfo)
    },
    async updatePassword () {
      delete this.userLocal.avatar
      await this.$store.dispatch('userManagement/updateUser', this.userLocal)
      await this.$store.dispatch('auth/deleteBearer')
      await this.$router.push({'name': 'page-login' }).then(() => {
        this.$vs.notify({
          title: this.$t('password.reset.notify.update_password.title'),
          text: this.$t('password.reset.notify.update_password.text'),
          color: 'success'
        })
      }).catch(() => {})
    }
  },
  created () {
    this.decodeJWT(this.$route.query.token)
  }
}
</script>
